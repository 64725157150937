import { Link } from "react-router-dom";
import { Problem } from "../../pages/TreePage";

interface Props {
    problem: Problem,
}

export default function TreeNode({problem}: Props) {

    const pos = {"--pos": (problem.xPos) +  "vw," + (problem.yPos) + "vh"} as React.CSSProperties; //TODO: Check if this actually works lmao
    const state = ["disabled", "active", "solved"][problem.state-1]
    const type = problem.type == "s" ? "Š" : problem.type.toUpperCase()

    return ( 
        <Link to={"uloha?id="+problem.id}>
        <div className="treeNode" style={pos}>
            <div className={`content ${state}`}>
                <h2>{problem.id}</h2>
            </div>
        </div>
        </Link>
     );
}