interface Props {
    x1: string,
    y1: string,
    x2: string,
    y2: string
}

function Line({x1,y1,x2,y2}: Props) {
    return ( <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="#ddd" strokeWidth="0.4vw" strokeLinecap="round"/> );
}

export default Line;