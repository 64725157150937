import { BrowserRouter } from 'react-router-dom';
import { useSpecialClassHandler } from './scripts/useSpecialClassHandler';

import "./styles/main.css"
import "./styles/specialClasses.css"

import MainPage from './pages/MainPage';

function App() {

  useSpecialClassHandler()

  return (
    <BrowserRouter>
      <MainPage/>
    </BrowserRouter>
  );
}

export default App;
