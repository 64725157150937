export type StrIndexedObj = {[key: string]: any}

//const defaultUrl = "http://localhost:8080"
const defaultUrl = "https://omega-post-416123.ey.r.appspot.com"

// Basic req functions
const sendReq = (opts: Object, url: string, endpoint: string, errMsgSetter?: Function) => {
  return fetch(url + endpoint, opts)
    .then(async (res) => {
      let temp = await res.json();
      temp.status = res.status;
      return temp;
    })
    .catch((err) => {
      console.log(err);
      if (errMsgSetter) {errMsgSetter("Něco se pokazilo")}
    });
};

export const sendGetReq = async (endpoint: string, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "GET",
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};

export const sendPostReq = async (endpoint: string, body: StrIndexedObj, errMsgSetter?: Function, url: string = defaultUrl) => {
  const opts = {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    }
  };
  return await sendReq(opts, url, endpoint, errMsgSetter);
};




// Abstract req functions
export const getProblems = async (setter: Function) => {

    let res = await sendGetReq("/archive/problems");

    if (res.data) {setter(res.data)}
    return
}