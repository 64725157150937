function LoadingDots() {
    return ( 
        <div className="centerAlign loadingDots row">
            <div className="dot"/>
            <div className="dot" style={{animationDelay: "0.2s"}}/>
            <div className="dot" style={{animationDelay: "0.4s"}}/>
        </div>
     );
}

export default LoadingDots;